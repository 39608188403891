import { LoaderStyle } from './loader.styled';

export const Loader = () => {

  return (
    <LoaderStyle>
        <div className='loader'></div>
        <p>Loading...</p>      
    </LoaderStyle>   
  );
}