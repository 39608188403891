import styled from 'styled-components';
import Background from './images/background.svg';

export const RegistrationContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative; /* Устанавливаем контекст для псевдоэлемента */
  display: flex; /* Если нужно разместить содержимое */
  justify-content: center;
  align-items: center;

  background: url(${Background}) no-repeat center center;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Затемнение черным цветом, прозрачность 50% */
    z-index: 1; /* Псевдоэлемент позади содержимого */
  }

  /* Содержимое контейнера поверх затемнения */
  > * {
    position: relative;
    z-index: 2;
  }
`;