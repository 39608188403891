import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 52px;
  margin-bottom: 79px;

  .title {
    font-family: Montserrat;
    font-size: 31px;
    font-weight: 700;
    line-height: 37.79px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
    
  .info-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .goods-info {
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 500;
    line-height: 20.72px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
`