import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const Notification = () => {
  return (<ToastContainer
    position="top-right"
    autoClose={2000}
    hideProgressBar={false}
    //newestOnTop={false}
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
    //transition={Bounce}
  />)
}