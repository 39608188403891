import { useEffect } from "react";
import { BasketContainer } from "./basket.styled";
import { getGoods } from "../../api/goods";
import showNotification from "../../components/Notification/notification-emmiter";
import { SUCCES_NOTIFICATION } from "../../components/Notification/notification-types";

export const Basket = () => {

  useEffect(() => {
    getGoods()
      .then(()=>{
        //showNotification('Запрос за товарами успешно завершился', SUCCES_NOTIFICATION);
      }).catch()
  }, []);

  return <BasketContainer>
    <p> Я страница корзины </p>
  </BasketContainer>
}