import { BrowserRouter, Routes, Route, Navigate } from 'react-router';
import { Main } from './pages/Main/Main';
import { Registration } from './pages/Registration/Registration';
import { Authorization } from './pages/Authorization/Authorization';
import { Product } from './pages/Product/Product';
import { Basket } from './pages/Basket/Basket';
import { Notification } from './components/Notification/Notification';
import { AuthContextProvider, useAuthContext } from './context/authContext';

const CheckAuth = ({children}) => {
  const context = useAuthContext();
  if (context.token === null) {
    return <Navigate to='/login' />
  }
  return children;
}
function App() {

  return (
    <BrowserRouter>
      <AuthContextProvider> 
        <Routes>
          <Route path='/' element={<CheckAuth><Main /></CheckAuth>} />
          <Route path='/product/:productId' element={<Product />} />
          <Route path='/basket' element={<Basket />} />
          <Route path='/login' element={<Authorization />} />
          <Route path='/registration' element={<Registration />} />
        </Routes>
        <Notification /> 
      </AuthContextProvider>      
    </BrowserRouter>
  );
}

export default App;