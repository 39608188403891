import { HeaderContainer } from "./header.styled";
import { Button } from "../../components/Button/Button";
import basketIcon from './images/basket.svg';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../context/authContext';

export const Header = () => {

  const context = useContext(AuthContext);

  const navigate = useNavigate();

  const clickHandler = () => {
    context.logOut();
    navigate('/');
  }

  return <HeaderContainer>
    <div className="title">НАША ПРОДУКЦИЯ</div>
    <div className="info-block">
      <div className="goods-info">
        <span>3 товара</span><br/>
        <span>на сумму 3 500 Р</span>
      </div>
      <img src={basketIcon} alt=''></img>
      <Button type='primary' onClick={clickHandler} title='Выйти'/>
    </div>
  </HeaderContainer>
}