import { createContext, useState } from "react";
import { useNavigate } from "react-router";
import { useContext } from 'react';

export const AuthContext = createContext({}); 

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const [token, setToken] = useState(window.localStorage.getItem('token') || null);

  const login = (token) => {
    window.localStorage.setItem('token', token);
    setToken(token);
    navigate('/');
  };
  const logOut = () => {
    window.localStorage.removeItem('token');
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ token, login, logOut}}>
      { children }
    </AuthContext.Provider>
  )
}