import styled from 'styled-components';

export const LoginFormContainer = styled.div`
  /* display: none; */
  border-radius: 3px; /* Скруглит все углы на 10px */
  width: 460px;
  height: 400px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(22, 21, 22, 1);
  
  .container {
    margin-left: 20px;
    margin-right: 20px;
    height: 100%;
  }

  .link-to-authorization {
    color: rgba(213, 140, 81, 1);
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
    text-align: right;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    padding-top: 9px;

    &__link {
      color: rgba(213, 140, 81, 1);
    }
  }

  .reg-title {
    font-family: Montserrat;
    font-size: 31px;
    font-weight: 700;
    line-height: 38px;
    text-align: center;
    text-underline-position: from-font;
    color: rgba(22, 21, 22, 1);
    margin-top: 13px;
    margin-bottom: 36px;
  }

  .reg-button {
    /* background-color: ${(props) => (props.type ==='primary' ? '#161516' : 'red')}; */
    width: 200px;
    height: 42px;
    background-color: #D58C51;
    border: none;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;


    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 12px;
    transition: background-color 0.3s ease; /* Плавный переход */

    &:hover {
      background-color: #B76D3E; /* Цвет при наведении */
    }  
  }

  .reg-login {
    width: 99%;
    height: 39px;
    margin-top: 4px;
    border-radius: 61px;
    border: 1px solid #D58C51;
    font-size: 16px;  
    font-family: Montserrat;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #161516;
    padding-left: 16px; 
    box-sizing: border-box;  
  }

  .reg-password {
    width: 99%;
    height: 39px;
    margin-top: 4px;
    border-radius: 61px;
    border: 1px solid #D58C51;
    font-size: 16px;  
    font-family: Montserrat;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #161516;
    padding-left: 16px; 
    box-sizing: border-box;  
  }

  .authreg-button {
    width: 200px;
    height: 42px;
    background-color: #D58C51;
    border: none;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 12px;
    transition: background-color 0.3s ease; /* Плавный переход */
  }

  #rules {
    border: 1px solid #D58C51;
  }

  .checkform-row {
    display: flex;
    align-items: end;
    padding-top: 5px;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 300;
    line-height: 13.5px;
    text-align: left;
  }

  authreg-button:hover {
    background-color: #b46e34;
  }

  .alert {
    color: red;
    font-family: Montserrat;
    font-size: 8px;
    font-weight: 300;
    line-height: 10px;
    text-align: left;
    padding-left: 19px;
    height: 7px;
  }

  .info {
    color: ${(props) => (props.color ==='white' ? 'white' : 'red')};
    text-align: center;
    font-family: Montserrat;
    font-size: 8px;
    font-weight: 300;
    line-height: 9.8px;
    padding-top: 8px;
  }

  .button {
    height: 90px;
    margin-top: 30px;
  }

`