import styled from 'styled-components';

export const ButtonContainer = styled.button`
  /* background-color: ${(props) => (props.type ==='primary' ? '#161516' : 'red')}; */
  width: 200px;
  height: 42px;
  background-color: #D58C51;
  border: none;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;


  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 12px;
  transition: background-color 0.3s ease; /* Плавный переход */

  &:hover {
    background-color: #B76D3E; /* Цвет при наведении */
  }  
`