import { ButtonContainer } from './button.styled';

export const Button = (props) => {
  const { title, onClick, disabled, type } = props;

  const clickHandler = () => {
    onClick();
  }

  return <ButtonContainer onClick={clickHandler} disabled={disabled} type={type}>{title}</ButtonContainer>;
}