import { Card } from '../../components/Card/Card';
import Styled from '../../components/Card/card.module.css';
import { Footer } from '../../layouts/Footer/Footer';
import { Header } from '../../layouts/Header/Header';
import { useState } from 'react';
//import { Product } from '../Product/Product';



export const Main = () => {

  const [product, setProducts] = useState([]);

  //if (window.localStorage.getItem('token')) navigate("/login")
  
  const pic1 = 'https://s3-alpha-sig.figma.com/img/07ef/e112/f50e999a23527bddfe200255a70a0b51?Expires=1737331200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=X1YSYfXpmGR8DC9HoyQ4IReLA9nrR-P7h~f5PvjNtjgA68TutGnXO52SgKIf8yxL1PfAM-k5cvWgYsFL~51gOGQkaNmf8h6fdnq4e7e9BJZ2lvWx89Sc0OM7e9Jt8-92EOPGHGFfhwwcyQzvXYOR9Reg1hAmLtNzbM74g6X0TKSf64mL6xcaWdgbg48IyLSfQ58ugrZQTK1XgkbMDjLbDs1ATFE4m8YEd2eqcH6oEmBZ0jBL7GY-nyh6VYNo~thYktmpeg6YFF320VYIW08dm705LI6baXKfor3JNqPpBgGjrYJQcZIZarTHsnF6YwRxy-RNM3Sd8-14Qdm3lcjM-g__';

  const pic2 = 'https://s3-alpha-sig.figma.com/img/b3d7/824f/1fd06c539d36475622f3b28d33af010b?Expires=1737331200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AbaVhuwfTcrekVnwG4jkWpKlwmO3R5o8zZHaDFTXWtnNURKKmpL8yQFUnU-szCc9CeA-m72fRahe8p-bG~rzlHNO9od9kczdjkMaVUK9hQukl2MZLw1-N6hQloi-aIMGzdVVDPuedwJPTEA3ANWXJMfHYAWD3Ae9mkgWWX9n~~aR6antWFxPL~59QhCFZk8hpwXSjDH7No6iqLdDKcuUZeMduMyRCnHIV2hp0FXPBn-NpQ10-3gV6ZbWHkbxXDPeX-Kb6-Z8zqiZtrsJFF2LGaJYQpz4OOPOOmdRq~0bdPqa0UgSwmzvm4q119yk0cSrpv1BOErblxPn50XQGQw4SQ__';

  const pic3 = 'https://s3-alpha-sig.figma.com/img/1eb2/16fe/bfbc78b496e9b7a846f037fbabd8f1fc?Expires=1737331200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iZvUhzoXdIaTCr6nsWitpw0ZioxFkXCTHLVxTFRESteAJCA2pFWZJYcL7uMVbPNbCATQhaUQU-vxEsaIhUhFboS2m1IzuihW4-LuoUJ1iPNfIOHjKDke6TA5hpxuFrgtPkR9S1ujtVIcD-Bu3DvsMILR3qiqvSZ00sHafDEyHe8-mf1CwcLfOifDIxfiIU1O7j~Y05gEWh065n~5UeDZYZ9UJbf5H7Rw6qWzc6Ndpf1TmgjKbIAKCDNTdKYweVNEZysbH9VY5pSb-i3JxmFiqVOOK3a3I7dGCFWrt6APekHJ-08EKhUqOrLKvSww98Wvr0-tYYvwXtc8r23TTiLvsQ__';

  const desc1 = 'Значимость этих проблем настолько очевидна, что укрепление и развитие структуры';

  const desc2 = 'Не следует, однако забывать, что реализация намеченных плановых';

  const desc3 = 'Значимость этих проблем настолько очевидна, что укрепление и развитие структуры обеспечивает широкому кругу';

  
  const clickHandler = () => {
    console.log('Hello, button!');
  }

  return (
    <div className="App">
      
      <div className={Styled['container']}>

        <Header />
        <div className={Styled['card-container']}>
          <Card title = 'Устрицы по рокфеллеровски' description = {desc1} img = {pic1} price = '2 700 ₽'/>
          <Card title = 'Свиные ребрышки на гриле с зеленью' description = {desc2} img = {pic2} price = '1 600 ₽'/>
          <Card title = 'Креветки по-королевски в лимонном соке' description = {desc3} img = {pic3} price = '1 820 ₽'/>
          <Card title = 'Устрицы по рокфеллеровски' description = {desc1} img = {pic1} price = '2 700 ₽'/>
          <Card img = 'https://random.imagecdn.app/500/500'/>
          <Card />
        </div> 
        <Footer />
      </div> 
      
    </div>
  );
  
}