import Styled from './card.module.css';
import Plus from '../../icons/Plus/Plus.svg';

export const Card = (props) => {
  
  const cardVisible = true;

  return cardVisible ? (
    <div className={Styled['card']}>
      <img className={Styled['card-img']} src={props.img===undefined ? 'https://random-image-pepebigotes.vercel.app/api/random-image' : props.img} alt=""></img>
      <h2 className={Styled['card-header']}>{props.title===undefined ? 'Заголовок' : props.title}</h2>      
      <p className={Styled['card-description']}>{props.description===undefined ? 'Описание товара' : props.description}</p>
      <div className={Styled['card-bottom']}>
        <p className={Styled['card-bottom-price']}>{props.price===undefined ? 'Цена' : props.price}</p>
        <p className='card-bottom-plus'><img src={Plus}/></p>
        {/* <Button title="+" onClick={null} /> */}
      </div>
      
    </div>   
  ) : "";
}