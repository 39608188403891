import axios from "axios";
import showNotification from '../components/Notification/notification-emmiter.js';
import { ERROR_NOTIFICATION } from '../components/Notification/notification-types.js';

export const axiosInstance = axios.create({
  //baseURL: 'http://127.0.0.1:9002/api/v1';
  baseURL: 'http://94.41.85.102:1122/api/v1'
});

axiosInstance.interceptors.response.use(
  (response)=>{
    return response;
  }, 
  (error)=>{
    console.log(error);
    showNotification(error.response.data.message || 'Запрос завершился ошибкой', ERROR_NOTIFICATION);
    return Promise.reject(error);
  }
);