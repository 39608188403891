import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { RegFormContainer } from "./regForm.styled";
import { registration } from "../../api/auth";
import { Loader } from '../Loader/Loader';
import { Button } from "../Button/Button";

export const RegForm = (props) => {

  const navigate = useNavigate();
  
  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues ] = useState({
    username: '',
    password: '',
    //confirmPassword: '',
    //confirmRules: false
  });

  const [errors, setErrors] = useState({
    username: '',
    password: '',
    //confirmPassword: '',
    //confirmRules: false
  });

  const [touched, setTouched] = useState({
    username: false,
    password: false,
    //confirmPassword: false,
    //confirmRules: false
  });

  const isFormErrors = Object.values(errors).some((error) => error.length > 0);
  const isFormTouched = !Object.values(touched).some((item) => item === false);

  const userNameValidation = (value) => {
    if (value.length >= 6) {
      setErrors((prevState) => ({...prevState, username: ''}));
    } else {
      setErrors((prevState) => ({...prevState, username: 'Имя пользователя не может быть короче 6 символов'}));
    }
  };

  const usernameChangeHandler = (e) => {
    
    if (touched.username === false) {
      setTouched((prevState) => ({...prevState, username: true}));
    }
    userNameValidation(e.target.value);
    setFormValues((prevState) => ({...prevState, username: e.target.value}));
  };

  const passwordChangeHandler = (e) => {
    if (touched.password === false) {
      setTouched((prevState) => ({...prevState, password: true}));
    }
    setFormValues((prevState) => ({...prevState, password: e.target.value}));
  };

  const confirmPasswordChangeHandler = (e) => {
    setFormValues((prevState) => ({...prevState, confirmPassword: e.target.value}));

    if (e.target.value !== formValues.password) {
      setErrors((prevState) => ({...prevState, confirmPassword: 'Пароли не совпадают'}));
    } else {
      setErrors((prevState) => ({...prevState, confirmPassword: ''}));
      //setisEnabledButton(false);
    }
  };

  const confirmRulesChangeHandler = (e) => {
    setFormValues((prevState) => ({...prevState, confirmRules: e.target.checked}));
  };

  const submitHandler = (e) => {
    console.log("Зашли в сабмит")
    //e.preventDefault();
    setIsLoading(true);

    // Логирование данных, которые мы отправляем на сервер
    console.log("Данные для регистрации:", formValues);

    registration(formValues.username, formValues.password)
      .then((response) => {
        console.log("Ответ от сервера:", response);
        navigate('/login'); // Перенаправляем на страницу входа
      })
      .catch((error) => {
        console.error("Ошибка регистрации:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  return <RegFormContainer /* onSubmit={submitHandler} */>
    <div className='container'>
      <div className='link-to-authorization'>
        <Link to='/login' className='link-to-authorization__link'>Авторизоваться</Link>
      </div>
        <h1 className='reg-title'>РЕГИСТРАЦИЯ</h1>
        <div className='form-row'>
          <input type='text' className="reg-login" placeholder='Логин' value={formValues.username} onChange={usernameChangeHandler} />
          <div className='alert'> {errors.username} </div>
          
          <input type='text' className="reg-password" placeholder='Пароль' value={formValues.password} onChange={passwordChangeHandler}/>
          <div className='alert'> {errors.password} </div>
          
          <input type='password' className="reg-password" placeholder='Подтвердите пароль' value={formValues.confirmPassword} onChange={confirmPasswordChangeHandler}/>
          <div className='alert'> {errors.confirmPassword} </div>
        </div>

        <div className='checkform-row'>
          <input type='radio' id='rules' onChange={confirmRulesChangeHandler}/>
          <label htmlFor='rules' id='rules-label'>Я согласен получать обновления на почту</label>
        </div>

        <div className="button">
          <Button title='Зарегистрироваться' onClick={submitHandler} disabled={isFormErrors || !isFormTouched}/>
        </div>

        {/* <button className="reg-button" type='submit'>
          Зарегистрироваться
        </button> */}

        {isLoading && <Loader />}
    </div>
  </RegFormContainer>
};