import { LoginFormContainer } from './loginForm.styled'
import { Link } from 'react-router';
import { useState } from "react";
import { login } from "../../api/auth";
import { Loader } from '../Loader/Loader';
import { Button } from "../Button/Button";
import { useContext } from 'react';
import { AuthContext } from '../../context/authContext';


export const LoginForm = (props) => {
  const context = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues ] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({});

  const usernameChangeHandler = (e) => {
    setFormValues((prevState) => ({...prevState, username: e.target.value}));
  };

  const passwordChangeHandler = (e) => {
    setFormValues((prevState) => ({...prevState, password: e.target.value}));
  };

  const submitHandler = (e) => {
    console.log("Зашли в сабмит")
    //e.preventDefault();
    setIsLoading(true);

    // Логирование данных, которые мы отправляем на сервер
    console.log("Данные для регистрации:", formValues);

    login(formValues.username, formValues.password)
      .then((response) => {
        console.log("Ответ от сервера:", response.data);
        context.login(response.data.token);
      })
      .catch((error) => {
        console.error("Ошибка авторизации:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  return <LoginFormContainer /* onSubmit={submitHandler} */>
    <div className='container'>
      
      <div className='link-to-authorization'><Link to='/registration' className='link-to-authorization__link'>Зарегистрироваться</Link></div>

      <h1 className='reg-title'>ВХОД</h1>

      <div className='form-row'>
        <input type='text' className="reg-login" placeholder='Логин' value={formValues.username} onInput={usernameChangeHandler} required/>
        <div className='alert'> {} </div>
        <input type='text' className="reg-password" placeholder='Пароль' value={formValues.password} onInput={passwordChangeHandler}/>
        <div className='alert'> {} </div>
      </div>

      {/* <button className="reg-button" type='submit'>
          Войти
      </button> */}

      <div className="button">
          <Button title='Войти' onClick={submitHandler}/>
        </div>

      {isLoading && <Loader />}
    </div>
  </LoginFormContainer>
};






























/*
export const LoginForm = (props) => {

  const [msgUname, setmsgUname] = useState(' ');

  const [msgPassw, setmsgPassw] = useState(' ');
  
  const [usernameText, setUsernameText] = useState('');

  const [passwText, setPasswText] = useState('');  

  const [formValues, setFormValues ] = useState({
    username: '',
    password: '',
  });

  const navigate = useNavigate();

  const usernameChangeHandler = (e) => {
    setUsernameText(e.target.value);  
    setFormValues((prevState) => ({...prevState, username: e.target.value}));
  }

  const passwordChangeHandler = (e) => {
    setPasswText(e.target.value);    
    setFormValues((prevState) => ({...prevState, password: e.target.value}));
  }

  const submitHandler = (e) => {
    e.preventDefault();
  }

  const clickHandler = () => {
    // Если всё ок, продолжаем
    // здесь будем выдавать ошибки setmsgUname и setmsgPassw
    /* ........тута......... */




    /*
    navigate('/cat');
  }

  return <LoginFormContainer onSubmit={submitHandler}>
    <div className='container'>

      {/* <div className='link-to-authorization'><a href="/Login"  className='link-to-authorization__link'>Зарегистрироваться</a></div> } 



      /*
      <div className='link-to-authorization'><Link to='/Loginistration' className='link-to-authorization__link'>Зарегистрироваться</Link></div>
      <div className='link-to-authorization'><Link to='/basket' className='link-to-authorization__link'>БАСКЕТ</Link></div>

      <h1 className='Login-title'>ВХОД</h1>

      <div className='form-row'>
        <input type='text' className="Login-login" placeholder='Логин' value={formValues.username} onInput={usernameChangeHandler} required/>
        <div className='alert'> {msgUname} </div>
        <input type='text' className="Login-password" placeholder='Пароль' value={formValues.password} onInput={passwordChangeHandler}/>
        <div className='alert'> {msgPassw} </div>
      </div>

      <button className="Login-button" type='submit'>
          Войти
      </button>

    </div>
  </LoginFormContainer>
}

*/